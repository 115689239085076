.card {
    padding: 10px 0;
    &:hover {
        transform: scale(1.1);
    }

    img {
        width: 90%;
    }
}

@media screen and (max-width: 991px) {
    .card {
        margin: auto;
        justify-content: center;
    }
}

