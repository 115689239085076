.wallet {
    cursor: pointer;
    
    img {
        width: 100%;
        margin-left: 5px;
        max-width: 150px;
    }

    p {
        font-family: 'Poppins';
        color: #1C2339;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        margin: 0;
    }

    span {
        font-family: 'Poppins';
        font-size: 8px;
        font-weight: 500;
    }

    .active {
        color: #4fc47a;
    }

    .comingsoon {
        color: #d64256;
    }
}

.disabled {
    cursor: not-allowed !important;
}
