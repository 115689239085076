.faq {
    margin: 40px 0;

    .title {
        background-image: url('../../assets/images/faq/FAQ.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
}
