.contact {
    padding: 50px 0;
	position: relative;
	margin: 100px 0;
	position: relative;
	background: url("../../assets/images/contact/bg.png");
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center;

    > img {
        position: absolute;
        left: 0;
        bottom: 100px;
        width: 250px;
        z-index: -10;
    }

    .main {
        position: relative;

        .wrap {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            padding: 20px 50px;

            .text {
                padding-right: 40px;

                h2 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 45px;
                    line-height: 125%;
                    color: #FFFFFF;
                }

                p {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 145.5%;
                    color: #FFFFFF;
                }
            }
        }
    }
}

@media screen and (max-width: 991px){
    .contact {
        margin-top: 0;

        .wrap {
            h2 {
                font-size: 35px !important;
            }
    
            p {
                font-size: 20px !important;
            }
        }
    }
}

@media screen and (max-width: 767px){
    .wrap {
        .text {
            padding-right: 20px !important;

            h2 {
                font-size: 26px !important;
	            line-height: 1.5 !important;
            }

            p {
                font-size: 16px !important;
	            line-height: 1.3 !important;
            }
        }
    }

}

@media screen and (max-width: 575px) {
    .contact {
        padding: 30px 0 !important;
        position: relative !important;
        margin: 40px 0 !important;
        text-align: center !important;

        .main {

            > img {
                width: 120% !important;
                transform: translateX(-20px) !important;
                height: 400px !important;
            }
    
            .wrap {
                padding: 20px 10px !important;
        
                .text {
                    padding-right: 0px !important;
        
                    h2 {
                        font-size: 22px !important;
                    }
        
                    p {
                        font-size: 22px !important;
                    }
                }
    
                img {
                    width: 150px !important;
                    text-align: center !important;
                }
            }
        }

    }
}

.social {
    .icons {
        margin: 30px 0;
        justify-content: space-evenly;

        a {
            font-size: 16px;
            color: #23262F;
            font-weight: 500;
            margin: 0px;
            position: relative;

            i {
                font-size: 25px !important;
            }
        }
    }
}
