.footer {
    color: #23262F;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;

    p {
        font-size: 18px;
    }

    a {
        font-size: 16px;
        color: inherit;
        margin: 0 10px;
    }

    img {
        width: 15px !important;
	    margin-right: 10px;
    }
}
